<template>
  <div class="testimonial-wrapper">
    <!-- <transition name="fade"> -->
    <div
      class="references-block"
      v-for="(item, index) in data"
      :key="index"
      :class="{ hidden: index !== count }"
    >
      <div class="block-content" ref="contentBlock">
        <div class="text">{{ item.text }}</div>
        <div class="author">{{ item.author }}</div>
      </div>
    </div>
    <!-- </transition> -->
    <div class="counter">
      <div
        class="dot"
        v-for="(item, index) in data"
        :class="{ 'dot-active': index === count }"
        :key="index"
        @click="move(index)"
      ></div>
    </div>
  </div>
</template>

<script>
import { client } from '../main';
// import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
export default {
  name: 'Testimonails-Section',
  props: {},
  data: function () {
    return {
      count: 0,
      data: [],
    };
  },
  created() {
    client.getEntries({ content_type: 'testimonial' }).then((data) => {
      let tempArr = [];
      data.items.forEach((el) => {
        let obj = {
          text: el.fields.testimonial,
          author: el.fields.author,
        };
        tempArr.push(obj);
      });
      this.data = tempArr;
    });
  },
  mounted() {
    this.counter = 0;
  },
  updated() {
    this.checkHeight();
  },
  methods: {
    move(index) {
      this.count = index;
    },
    checkHeight() {
      // this.$refs.contentBlock.forEach((el) => {});
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../assets/grid.scss';
.testimonial-wrapper {
  width: 100%;
  padding: 5vw 10vw;
  box-sizing: border-box;
  background-color: #ee8532;
  position: relative;
  //   display: flex;
}

.references-wrapper {
  background-color: red;
  display: flex;
  width: 100%;
  margin: 0 auto;
}

.references-slider {
  display: flex;
  transition: transform 300ms ease-out;
  border-radius: 16px;
}

.references-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100%;
  padding: 24px;
  text-align: center;
  box-sizing: border-box;
  transition: opacity 300ms ease-out;
  margin-right: 16px;
  //   font-size: clamp(1rem, -0.675rem + 3.5vw, 1.7rem);

  max-height: 60vh;
  height: 50vh;
  overflow: hidden;

  .author {
    margin-top: 16px;
    color: #333333;
    font-family: 'Vulf Sans Medium';
    font-size: 18px;
  }
}

.counter {
  display: flex;
  width: 100%;
  margin-top: 20px;
  justify-content: center;
}

.dot {
  height: 20px;
  width: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-right: 8px;
  &-active {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.hidden {
  display: none;
}

@media (max-width: 651px) {
  .testimonial-wrapper {
    padding: 3vw 3vw 10vw 3vw;
  }
  .references-block {
    max-height: unset;
    height: 70vh;
  }
}

@include xs {
  .references-block {
    font-size: 1rem;
  }
}

@include sm {
  .references-block {
    font-size: 1.1rem;
  }
}
@include md {
  .references-block {
    font-size: 1.4rem;
  }
}

@include lg {
  .references-block {
    font-size: 1.6rem;
  }
}

@include xl {
  .references-block {
    font-size: 1.8rem;
  }
}

@include xxl {
  .references-block {
    font-size: 2rem;
  }
}
</style>
