<template>
  <div class="intro">
    <div class="text" v-html="body"></div>
  </div>
</template>

<script>
export default {
  name: 'Intro-Section',
  props: {
    body: String,
  },
  data: function () {
    return {};
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.intro {
  height: clamp(900px, 100vh, 1200px);
  width: 100%;
  padding: 20vw;
  box-sizing: border-box;
  background-color: #fefefe;
  display: flex;
  align-items: center;

  p {
    font-size: 44px;
  }
}
.text ::v-deep {
  color: black;
  p {
    font-size: clamp(1.5rem, -0.875rem + 4.66vw, 2rem);
    &:first-child {
      margin-bottom: 32px;
    }
  }
  b {
    color: #196492;
  }
}

@media (max-width: 651px) {
  .intro {
    padding: 5vw;
  }
}
</style>
