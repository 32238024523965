import Vue from 'vue';
import App from './App.vue';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

var contentful = require('contentful');
export var client = contentful.createClient({
  space: 'qoahmc75ud4k',
  accessToken: 'UYtKJX2cqnaRkC_3q-iMOO1e4mFidk94_STv3EjoTJE',
});

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount('#app');
