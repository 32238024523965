<template>
  <div class="info">
    <div class="info-content">
      <h3>{{ title ? title : '' }}</h3>
      <p>{{ body ? body : '' }}</p>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'Info-Section',
  props: {
    title: String,
    body: String,
    showImage: Boolean,
  },
  data: function () {
    return {};
  },
  mounted() {
    this.comeintoview();
  },
  methods: {
    comeintoview() {
      const elements = Array.from(
        document.getElementsByClassName('info-content')
      );
      elements.forEach((element) => {
        let triggerElement = element;

        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: triggerElement,
            start: '-50% center',
            end: '10% center',
            scrub: true,
            
          },
        });
        tl.to(triggerElement, {
          y: '0%',
          opacity: 1,
        });
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h3 {
  margin: 0;
}

.info {
  width: 100%;
  background-color: #f1f1f1;
  padding: 3vw 20vw;
  box-sizing: border-box;
  font-size: clamp(1.2rem, -0.875rem + 4.66vw, 1.5rem);
  display: flex;
  overflow: hidden;
  position: relative;
  &:last-child {
    padding-bottom: 100px;
  }
}

.info-content {
  transform: translateY(50%);
  opacity: 0;
  p {
    margin: 0;
  }
}
.image {
  width: 300px;
  height: 300px;
}

@media (max-width: 651px) {
  .info {
    padding: 5vw;
  }
}
</style>
