<template>
  <div class="cost-wrapper">
    <h3>{{ title }}</h3>
    <div class="intro" v-html="info"></div>
    <div class="blocks" v-if="infoChunk.length > 0">
      <div
        class="test"
        v-for="(item, index) in infoChunk"
        :key="index"
        v-html="item"
      ></div>
    </div>
    <div class="total" v-html="total"></div>
  </div>
</template>

<script>
import { client } from '../main';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
export default {
  name: 'Cost-List',
  props: {},
  data: function () {
    return {
      title: '',
      infoChunk: [],
      total: '',
      info: '',
    };
  },
  created() {
    client.getEntries({ content_type: 'cost' }).then((data) => {
      console.log(data, 'DATA');
      data.items.sort((p1, p2) =>
        p1.fields.order < p2.fields.order
          ? 1
          : p1.fields.order > p2.fields.order
          ? -1
          : 0
      );
      console.log(data, 'XDATA');
      this.title = data.items[data.items.length - 1].fields.title;
      let total = data.items.shift();
      let intro = data.items.pop();
      let tempArr = [];
      data.items.forEach((el) => {
        tempArr.push(documentToHtmlString(el.fields.breakdown));
      });

      this.infoChunk = tempArr;
      this.total = documentToHtmlString(total.fields.breakdown).replace(
        /\n/g,
        `<br>`
      );
      this.info = documentToHtmlString(intro.fields.breakdown).replace(
        /\n/g,
        `<br>`
      );
    });
  },
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.cost-wrapper {
  //   height: 100vh;
  width: 100%;
  padding: 3vw 20vw;
  box-sizing: border-box;
  background-color: #196492;
  position: relative;
  font-size: clamp(1.2rem, -0.875rem + 4.66vw, 1.5rem);

  img {
    width: 300px;
    height: 300px;
    mask-image: url('../assets/imgs/star.svg');
    margin-right: 48px;
    margin-top: 48px;
  }
}
h3 {
  font-family: 'Vulf Sans Medium';
  color: white;
}
b {
  color: #196492;
}

.blocks {
  display: flex;
  flex-wrap: wrap-reverse;
}
.test {
  padding: 12px 24px;
  margin: 5px;
  background-color: white;
  width: 100%;
  border-radius: 80px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  color: white;
}

.intro {
  margin-bottom: 64px;
}

.total {
  margin-top: 64px;
  width: 100%;
  text-align: center;
  margin-bottom: 64px;
}

.intro,
.total {
  color: white;
}

.bio ::v-deep {
  p {
    margin: 0;
  }
}

.intro ::v-deep {
  a {
    color: white;
  }
}

.content {
  max-width: 900px;
}

@media (max-width: 651px) {
  .cost-wrapper {
    padding: 5vw;
    font-size: 18px;
  }
  ul {
    padding-inline-start: 20px;
  }
}
</style>
