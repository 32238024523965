<template>
  <div class="download row gap">
    <div class="downloadbutton col-xs-12 col-sm-4" @click="openUrl()">
      <img src="../assets/imgs/download.svg" alt="" />
    </div>
    <div class="content col-xs-12 col-sm-8">
      <h3>{{ title }}</h3>
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import { client } from '../main';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'Download-Section',
  props: {},
  data: function () {
    return {
      title: '',
      text: '',
      url: '',
    };
  },
  created() {
    client.getEntries({ content_type: 'download' }).then((data) => {
      this.title = data.items[0].fields.titel;
      this.text = data.items[0].fields.beschreib;
      this.url = data.items[0].fields.pdf.fields.file.url;
    });
  },
  mounted() {},
  methods: {
    openUrl() {
      window.open(this.url, '_blank');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.download {
  background-color: white;
  padding: 5vw;
  h3 {
    font-size: clamp(1.2rem, -0.875rem + 4.66vw, 1.5rem);
    margin: 0;
  }
  p {
    font-size: 24px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.downloadbutton {
  display: flex;
  justify-content: flex-end;
  padding-right: 64px;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  img {
    max-width: 200px;
    width: 100%;
  }
}

@media (max-width: 651px) {
  .download {
    display: flex;
    justify-content: center;
    padding: 8vw 5vw;
    h3 {
      margin-bottom: 16px;
    }
    p {
      font-size: unset;
    }
  }
  .downloadbutton {
    padding: 0;
    justify-content: center;
    margin-bottom: 24px;
  }
}
</style>
