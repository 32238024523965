<template>
  <div v-if="data.length > 0" class="details-wrapper">
    <div class="desktop" v-if="!mobile">
      <div class="duration">
        <h3>{{ data[0].title }}</h3>
        <p v-html="data[0].body"></p>
        <img src="../assets/imgs/circle.svg" alt="" />
      </div>
      <div class="place">
        <h3>{{ data[2].title }}</h3>
        <p v-html="data[2].body"></p>
        <img src="../assets/imgs/shape_2.svg" alt="" />
      </div>
      <div class="dead-line">
        <div class="content">
          <h3>{{ data[4].title }}</h3>
          <p v-html="data[4].body"></p>
        </div>
        <img src="../assets/imgs/quarter.svg" alt="" />
      </div>
      <div class="structure">
        <div class="content">
          <h3>{{ data[3].title }}</h3>
          <p v-html="data[3].body"></p>
        </div>
        <img src="../assets/imgs/square.svg" alt="" />
      </div>
      <div class="subscription">
        <h3>{{ data[1].title }}</h3>
        <p v-html="data[1].body"></p>
        <img src="../assets/imgs/star.svg" alt="" />
      </div>
    </div>
    <div class="mobile" v-if="mobile">
      <div class="mobile-item" :style="{ 'background-color': '#EE8532' }">
        <h3>{{ data[0].title }}</h3>
        <p v-html="data[0].body"></p>
      </div>
      <div
        class="mobile-item"
        :style="{ 'background-color': '#D24F38', color: 'white' }"
      >
        <h3>{{ data[2].title }}</h3>
        <p v-html="data[2].body"></p>
      </div>
      <div class="mobile-item" :style="{ 'background-color': '#F0A9B1' }">
        <h3>{{ data[4].title }}</h3>
        <p v-html="data[4].body"></p>
      </div>
      <div
        class="mobile-item"
        :style="{ 'background-color': '#1C8741', color: 'white' }"
      >
        <h3>{{ data[3].title }}</h3>
        <p v-html="data[3].body"></p>
      </div>
      <div
        class="mobile-item"
        :style="{ 'background-color': '#196492', color: 'white' }"
      >
        <h3>{{ data[1].title }}</h3>
        <p v-html="data[1].body"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { client } from '../main';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
export default {
  name: 'Detail-View',
  props: {},
  data: function () {
    return {
      mobile: '',
      data: [],
    };
  },
  created() {
    client.getEntries({ content_type: 'bits' }).then((data) => {
      data.items.sort((p1, p2) =>
        p1.fields.order > p2.fields.order
          ? 1
          : p1.fields.order < p2.fields.order
          ? -1
          : 0
      );
      let tempArr = [];
      data.items.forEach((el) => {
        let obj = {
          title: el.fields.title,
          body: documentToHtmlString(el.fields.body).replace(/\n/g, `<br>`),
        };
        tempArr.push(obj);
      });
      this.data = tempArr;
    });
    this.mobile = window.isMobile;
  },
  mounted() {
    setTimeout(this.test, 200);
  },
  methods: {
    test() {
      gsap.set('.duration', {
        top: 100,
        rotation: 120,
      });
      gsap.to('.duration', {
        scrollTrigger: {
          trigger: '.details-wrapper',
          start: 'top +=200',
          end: 'bottom +=400',
          scrub: true,
        },
        top: 'calc(100vh - 20vw)',
        rotation: 0,
      });

      gsap.set('.subscription', {
        top: 100,
        rotation: 120,
      });
      gsap.to('.subscription', {
        scrollTrigger: {
          trigger: '.details-wrapper',
          start: 'top +=200',
          end: 'bottom +=400',
          scrub: true,
        },
        top: 'calc(100vh - 20vw)',
        rotation: 0,
      });

      gsap.set('.dead-line', {
        top: '45%',
        rotation: -70,
      });
      gsap.to('.dead-line', {
        scrollTrigger: {
          trigger: '.details-wrapper',
          start: 'top +=200',
          end: 'bottom +=400',
          scrub: true,
        },
        top: 'calc(100vh - 20vw)',
        rotation: -20,
        x: '+=50',
      });

      gsap.set('.place', {
        top: '75vh',
        rotation: 3,
      });
      gsap.to('.place', {
        scrollTrigger: {
          trigger: '.details-wrapper',
          start: 'top +=200',
          end: 'bottom +=200',
          scrub: true,
        },
        top: 'calc(100vh - 20vw)',
        left: '40vw',
        rotation: -9,
      });

      gsap.set('.structure', {
        top: '20vh',
        rotation: 3,
      });
      gsap.to('.structure', {
        scrollTrigger: {
          trigger: '.details-wrapper',
          start: 'top +=200',
          end: 'bottom +=200',
          scrub: true,
        },
        top: 'calc(100vh - 30vw)',
        right: '20vw',
        rotation: -4,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.details-wrapper {
  height: 100vh;
  width: 100%;
  padding: 5vw;
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
}

.duration,
.subscription-end,
.dead-line,
.structure,
.place {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 20vw;
  width: fit-content;
  h3,
  p {
    position: relative;
    z-index: 1;
    font-size: 2vw;
    margin: 0;
    transform: translateY(-10px);
  }
  h3 {
    font-family: 'Vulf Sans Medium';
  }
  img {
    position: absolute;
    // top: 0;
    width: 20vw;
  }
  &:hover {
    z-index: 500;
  }
}

.duration {
  position: absolute;
  left: 80px;
  top: 100px;
  //   transform: rotate(180deg);
}
.subscription {
  height: 20vw;
  right: 5vw;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h3 {
    font-size: 1.7vw;
    color: white;
    z-index: 1;
    margin: 0;
  }
  p {
    font-size: 1.5vw;
    margin: 0;
    color: white;
    z-index: 1;
    // transform: rotate(0deg);
  }
  img {
    position: absolute;
    top: 0;
    width: 20vw;
  }
}

.dead-line {
  height: 15vw;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 45%;
  left: 15vw;
  transform: rotate(-70deg);
  .content {
    padding: 12px;
  }
  h3,
  p {
    font-size: 2vw;
  }

  img {
    width: 20vw;
  }
}
.structure {
  height: 30vw;
  position: absolute;
  top: 20vh;
  right: 30vw;
  transform: rotate(-18deg);
  .content {
    width: 20vw;
  }
  h3,
  p {
    font-size: 2vw;
    color: white;
  }
  img {
    width: 30vw;
  }
}

.place {
  height: 15vw;
  text-align: center;
  position: absolute;
  top: 75vh;
  left: 40vw;
  transform: rotate(3deg);
  h3,
  p {
    font-size: 2vw;
  }
  img {
    width: 30vw;
  }
}

.mobile-item {
  width: 100%;
  padding: 24px 32px 32px 32px;
  box-sizing: border-box;
  border-radius: 32px;
  margin-bottom: 16px;
  h3 {
    margin: 0;
  }
}

@media (max-width: 651px) {
  .details-wrapper {
    max-width: 100vw;
    overflow: hidden;
    height: unset;
    padding-top: 32px;
  }
}
</style>
