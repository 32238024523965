<template>
  <div class="header-item">
    <canvas id="canvas3d"></canvas>
  </div>
</template>

<script>
import { Application } from '@splinetool/runtime';

export default {
  name: 'Header-Element',
  data: function () {
    return {};
  },
  created() {},
  mounted() {
    const canvas = document.getElementById('canvas3d');

    const app = new Application(canvas);
    if (window.isMobile) {
      app.load('https://prod.spline.design/J23t4erDrVUV4Bqg/scene.splinecode');
    } else {
      app.load('https://prod.spline.design/T1fE0pvKtH7XUMUa/scene.splinecode');
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header-item {
  height: 100vh;
  width: 100%;
  background-color: pink;
  position: relative;
  z-index: 2;
}

.scroll {
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.body {
  a {
    .spline-watermark {
      display: none;
    }
  }
}
</style>
