<template>
  <div id="app">
    <!-- <Menu v-on:send="open()"></Menu> -->
    <!-- <Transition name="slide-fade">
      <div class="counsellors" v-show="showCounsellors">
        <Counsellors v-on:close="close()"></Counsellors>
      </div>
    </Transition> -->
    <div class="app-contents">
      <div v-if="showNotification" class="notification-message">
        <img src="./assets/imgs/x.svg" alt="" @click="closeNotification()" />
        <p>
          <b>Lieber Besucher</b> <br />
          Aufgrund technischer Probleme mit dem Mail-Formular gingen gewisse
          Anmeldungen auf dem Weg zu uns verloren. Falls sie sich angemeldet
          haben und noch keine Antwort erhalten haben, bitten wir sie sich
          erneut anzumelden oder uns direkt eine Mail zu senden. (Adresse im
          Footer)
        </p>
      </div>
      <div id="header">
        <HeaderThree></HeaderThree>
      </div>
      <div id="intro">
        <Intro id="intro-element" :body="headerText"></Intro>
      </div>
      <div class="testimonials">
        <Testimonials></Testimonials>
      </div>
      <div id="info" v-if="infoData.length > 0">
        <Info
          v-for="(item, index) in infoData"
          :key="index"
          :title="item.fields.title"
          :body="item.fields.body"
          :showImage="index == 0 ? true : false"
        ></Info>
      </div>

      <div class="details">
        <Details></Details>
      </div>
      <div class="cost">
        <Cost></Cost>
      </div>
      <Download></Download>

      <div class="signup">
        <Signup></Signup>
      </div>
      <div class="lead">
        <Lead></Lead>
      </div>
      <div class="footer row gap">
        <div class="footer-col-left col-xs-12 col-sm-8" v-html="leftCol"></div>
        <div
          class="footer-col-right col-xs-12 col-sm-4"
          v-html="rightCol"
        ></div>
      </div>
    </div>

    <div class="logo">
      <svg
        id="Ebene_1"
        data-name="Ebene 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300 199.74"
      >
        <rect class="cls-1" x="0.2" width="99.87" height="99.87" />
        <path
          class="cls-1"
          d="M100.07,99.87h0a99.87,99.87,0,0,1,99.87,99.87v0a0,0,0,0,1,0,0H100.07a0,0,0,0,1,0,0V99.87A0,0,0,0,1,100.07,99.87Z"
        />
        <path
          class="cls-1"
          d="M199.93,0h0A99.87,99.87,0,0,1,299.8,99.87v0a0,0,0,0,1,0,0H199.93a0,0,0,0,1,0,0V0a0,0,0,0,1,0,0Z"
          transform="translate(299.8 -199.93) rotate(90)"
        />
        <circle class="cls-1" cx="150" cy="49.93" r="49.93" />
      </svg>
    </div>
  </div>
</template>

<script>
import { client } from './main';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import HeaderThree from './components/threedee.vue';
import Info from './components/Info.vue';
import Intro from './components/Intro.vue';
import Details from './components/Details.vue';
import Lead from './components/Lead.vue';
import Cost from './components/Cost.vue';
import Signup from './components/Signup.vue';
import Testimonials from './components/Testimonials.vue';
import Download from './components/Download.vue';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'App',
  components: {
    Info,
    HeaderThree,
    Testimonials,
    Intro,
    Details,
    Lead,
    Cost,
    Signup,
    Download,
  },
  data: function () {
    return {
      infoData: [],
      headerText: '',
      test: 'TEST',
      showCounsellors: false,
      leftCol: '',
      rightCol: '',
      showNotification: true,
    };
  },
  created() {
    client.getEntries({ content_type: 'info' }).then((data) => {
      this.infoData = data.items;
    });
    client.getEntries({ content_type: 'introText' }).then((data) => {
      let richText = documentToHtmlString(data.items[0].fields.intro);
      this.headerText = richText;
    });
    client.getEntries({ content_type: 'footer' }).then((data) => {
      this.leftCol = documentToHtmlString(
        data.items[0].fields.columnLeft
      ).replace(/\n/g, `<br>`);
      this.rightCol = documentToHtmlString(
        data.items[0].fields.columnRight
      ).replace(/\n/g, `<br>`);
    });
  },
  mounted() {
    this.tester();
  },
  methods: {
    open() {
      this.showCounsellors = true;
    },
    close() {
      this.showCounsellors = false;
    },
    closeNotification() {
      this.showNotification = false;
    },
    tester() {
      gsap.to('#intro', {
        scrollTrigger: {
          trigger: '#header',
          start: 'top center',
          end: 'bottom top',
          scrub: true,
          pinSpacing: true,
        },
        opacity: 1,
        top: '0',
      });
    },
  },
};
</script>

<style lang="scss">
@use 'sass:math';
@import './assets/grid.scss';
@font-face {
  font-family: 'Vulf Sans';
  src: url('./assets/VulfSans-Regular.woff2') format('woff2'),
    url('./assets/VulfSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Vulf Sans Medium';
  src: url('./assets/VulfSans-Medium.woff2') format('woff2'),
    url('./assets/VulfSans-Medium.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

.html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Vulf Sans';
}

#app {
  font-family: 'Vulf Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#intro {
  opacity: 0;
  position: relative;
  top: -50%;
}
p {
  margin: 0;
}

.notification-message {
  padding: 32px 24px 24px 24px;
  box-sizing: border-box;
  width: 400px;
  border: 1px solid #e6e6e6;
  background-color: white;
  border-radius: 16px;
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 1000;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);

  img {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;

    &:hover {
      background-color: #e6e6e6;
      border-radius: 4px;
    }
  }
}

.logo {
  width: 100px;
  position: fixed;
  top: 24px;
  left: 24px;
  z-index: 70000;
  mix-blend-mode: difference;
}

.counsellors {
  position: fixed;
  z-index: 30000;
}

.slide-fade-enter-active {
  transition: all 0.5s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.8s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translate(100vw, 0px);
}

.cls-1 {
  fill: white;
}

.footer {
  padding: 3vw;
  box-sizing: border-box;
  background-color: #1d1d1d;
  color: white;
  &-col-right {
    text-align: end;
  }
}

@media (max-width: 651px) {
  .logo {
    width: 50px;
  }

  .col-right {
    text-align: unset;
  }

  .notification-message {
    width: calc(100vw - 32px);
    right: 16px;
    bottom: 16px;
  }
}
</style>
