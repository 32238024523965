<template>
  <div class="lead-wrapper">
    <div class="content row gap">
      <div class="image col-xs-12 col-sm-4">
        <img :src="image" alt="" />
      </div>

      <div class="content col-xs-12 col-sm-8">
        <h3>{{ title }}</h3>
        <div class="bio" v-html="bio"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { client } from '../main';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
export default {
  name: 'Lead-Section',
  props: {},
  data: function () {
    return {
      title: '',
      image: '',
      bio: '',
    };
  },
  created() {
    client.getEntries({ content_type: 'about' }).then((data) => {
      this.title = data.items[0].fields.title;
      this.image = data.items[0].fields.portrait.fields.file.url;

      this.bio = documentToHtmlString(data.items[0].fields.bio).replace(
        /\n/g,
        `<br>`
      );
    });
  },
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.lead-wrapper {
  width: 100%;
  padding: 5vw;
  box-sizing: border-box;
  background-color: pink;
  position: relative;
  font-size: clamp(1.2rem, -0.875rem + 4.66vw, 1.5rem);

  img {
    width: 100%;
    mask-image: url('../assets/imgs/star.svg');
    mask-repeat: no-repeat;
    max-width: 300px;
  }
}
.border {
  background-color: white;
  mask-image: url('../assets/imgs/star.svg');
  mask-repeat: no-repeat;
}
.image {
  display: flex;
  justify-content: flex-end;
  height: fit-content;
  padding-right: 24px;
}

h3 {
  font-family: 'Vulf Sans Medium';
}
b {
  color: #196492;
}

.bio ::v-deep {
  p {
    margin: 0;
    margin-bottom: 8p;
  }
}

@media (max-width: 651px) {
  .lead-wrapper {
    font-size: 18px;
  }

  .image {
    justify-content: center;
  }
}
</style>
