<template>
  <div class="signup">
    <form ref="form" id="signup-form" v-on:submit.prevent="submit">
      <h3>Anmeldung</h3>
      <div class="success-message" v-if="mailIsSuccessfullySent">
        Vielen Dank für deine Anmeldung!
      </div>
      <div class="columns row gap" v-if="!mailIsSuccessfullySent">
        <div class="col-left col-xs-12 col-md-6">
          <div class="form-group">
            <label class="label">Name <span class="text-danger">*</span></label>
            <input
              type="text"
              v-model.trim="fullname"
              class="form-control form-control-lg"
              required
            />
          </div>

          <div class="form-group">
            <label class="label">Mail <span class="text-danger">*</span></label>
            <input
              type="email"
              v-model.trim="email"
              class="form-control form-control-lg"
              required
            />
          </div>

          <div class="form-group">
            <label class="label"
              >Handynummer <span class="text-danger">*</span></label
            >
            <input
              type="tel"
              placeholder="0XX XXX XX XX"
              v-model.trim="mobile"
              class="form-control form-control-lg"
              pattern="([0-9]{1,3}).([0-9]{1,3}.[0-9]{2}.[0-9]{2})"
              required
            />
          </div>

          <div class="form-group">
            <label class="label"
              >Strasse <span class="text-danger">*</span></label
            >
            <input
              type="text"
              v-model.trim="address"
              class="form-control form-control-lg"
              required
            />
          </div>

          <div class="form-group">
            <label class="label">PLZ <span class="text-danger">*</span></label>
            <input
              type="number"
              v-model.trim="plz"
              class="form-control form-control-lg"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              maxlength="4"
              required
            />
          </div>

          <div class="form-group">
            <label class="label">Ort <span class="text-danger">*</span></label>
            <input
              type="text"
              v-model.trim="city"
              class="form-control form-control-lg"
              required
            />
          </div>
        </div>
        <div class="col-right col-xs-12 col-md-6">
          <div class="form-group">
            <label class="label"
              >Geburtsdatum <span class="text-danger">*</span></label
            >
            <input
              type="date"
              v-model.trim="birthdate"
              class="form-control form-control-lg"
              required
            />
          </div>

          <div class="form-group">
            <label class="label"
              >Derzeitige Tätigkeit <span class="text-danger">*</span></label
            >
            <input
              type="text"
              v-model.trim="currentOccupation"
              class="form-control form-control-lg"
              required
            />
          </div>

          <div class="form-group">
            <label class="label"
              >Beruf <span class="text-danger">*</span></label
            >
            <input
              type="text"
              v-model.trim="job"
              class="form-control form-control-lg"
              required
            />
          </div>

          <div class="form-group">
            <label class="label"
              >Gemeinde <span class="text-danger">*</span></label
            >
            <input
              type="text"
              v-model.trim="community"
              class="form-control form-control-lg"
              required
            />
          </div>

          <div class="form-group">
            <label class="label"
              >Module <span class="text-danger">*</span></label
            >
            <select
              type="select"
              v-model.trim="modules"
              class="form-control form-control-lg"
              required
            >
              <option value="Modul12">Modul 1 & 2</option>
              <option value="Ganze Ausbildung" selected>
                Ganze Ausbildung
              </option>
            </select>
          </div>

          <div class="form-group">
            <label class="label"
              >Motivation <span class="text-danger">*</span></label
            >
            <textarea
              type="date"
              v-model.trim="motivation"
              class="form-control form-control-lg"
              required
            />
          </div>
        </div>
      </div>
      <div class="send-button" v-if="!mailIsSuccessfullySent">
        <button>Anmelden</button>
      </div>
    </form>
  </div>
</template>

<script>
import { required, minLength, email } from '@vuelidate/validators';
import emailjs from '@emailjs/browser';

export default {
  name: 'Signup-Section',
  props: {},
  data: function () {
    return {
      fullname: '',
      email: '',
      mobile: '',
      address: '',
      plz: '',
      city: '',
      birthdate: '',
      currentOccupation: '',
      job: '',
      modules: '',
      motivation: '',
      community: '',
      message: '',
      mail: 'lena@lenagabathuler.ch',
      mailIsSuccessfullySent: false,
    };
  },
  validations: {
    fullname: { required },
    email: { required, email },
    mobile: { required, minLength: minLength(10) },
    address: { required },
    plz: { required, minLength: minLength(4) },
    city: { required },
    birthdate: { required },
    currentOccupation: { required },
    job: { required },
    modules: { required },
    motivation: { required },
    community: { required },
  },
  methods: {
    submit: function () {
      const payload = {
        fullname: this.fullname,
        email: this.email,
        mobile: this.mobile,
        address: this.address,
        plz: this.plz,
        city: this.city,
        birthdate: this.birthdate,
        currentOccupation: this.currentOccupation,
        job: this.job,
        modules: this.modules,
        motivation: this.motivation,
        community: this.community,
      };

      emailjs
        .send(
          'service_pjtmzf8',
          'template_mo1f748',
          payload,
          'H-bKH3B7up3gM9ZF0'
        )
        .then(
          (result) => {
            console.log('SUCCESS!', result.text);
            this.sendConfirmation();
            this.mailIsSuccessfullySent = true;
            this.resetData();
          },
          (error) => {
            console.log('FAILED...', error.text);
            alert(
              'Sorry! Irgendetwas ist schief gelaufen bei der Anmeldung. Versuche es später noch einmal!'
            );
          }
        );
    },

    resetData() {
      this.fullname = '';
      this.email = '';
      this.mobile = '';
      this.address = '';
      this.plz = '';
      this.city = '';
      this.birthdate = '';
      this.currentOccupation = '';
      this.job = '';
      this.modules = '';
      this.motivation = '';
      this.community = '';
    },

    sendConfirmation() {
      const responsepayload = {
        fullname: this.fullname,
        email: this.email,
      };

      emailjs
        .send(
          'service_pjtmzf8',
          'template_yq3dbfk',
          responsepayload,
          'H-bKH3B7up3gM9ZF0'
        )
        .then(
          (result) => {
            console.log('SUCCESS!', result.text);
          },
          (error) => {
            console.log('FAILED...', error.text);
            alert(
              'Sorry! Irgendetwas ist schief gelaufen bei der Anmeldung. Versuche es später noch einmal!'
            );
          }
        );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h3 {
  font-family: 'Vulf Sans Medium';
}
.signup {
  background-color: #d24f38;
  width: 100%;
  box-sizing: border-box;
  padding: 3vw 20vw;
  display: flex;
  font-size: clamp(1.2rem, -0.875rem + 4.66vw, 1.5rem);
}
#signup-form {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.success-message {
  margin-bottom: 64px;
}

.columns {
  width: 100%;

  .col-left {
  }
  .col-left,
  .col-right {
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .label {
    margin-bottom: 4px;
    color: white;
  }
  input {
    height: 48px;
    border-radius: 24px;
    border: none;
    box-sizing: border-box;
    padding: 0 16px 0 16px;
    font-family: 'Vulf Sans Medium';
    font-size: 18px;
  }
  textarea {
    min-height: 96px;
    max-height: 96px;
    border-radius: 24px;
    border: none;
    font-family: 'Vulf Sans';
    font-size: 18px;
    padding: 0 16px 0 16px;
    resize: none;
  }
  select {
    height: 48px;
    border-radius: 24px;
    border: none;
    box-sizing: border-box;
    padding: 0 16px 0 16px;
    font-family: 'Vulf Sans Medium';
    font-size: 18px;
    background: url("data:image/svg+xml,<svg height='16px' width='16px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
      no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
    background-color: white;
  }
}

.send-button {
  padding-right: 16px;
  padding-top: 16px;
  display: flex;
  justify-content: flex-end;
}
button {
  height: 48px;
  border-radius: 24px;
  background-color: black;
  color: white;
  padding: 0 16px;
  font-family: 'Vulf Sans Medium';
  font-size: 18px;
  border: none;
  padding-bottom: 2px;
  box-sizing: border-box;
}

@media (max-width: 651px) {
  .signup {
    padding: 5vw;
  }
  .send-button {
    padding: 0 16px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-bottom: 32px;
    button {
      width: 100%;
    }
  }
}
@media (max-width: 961px) {
  .gap {
    margin: 0 !important;
  }
}
</style>
